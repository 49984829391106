import {
    ADXFilterValue,
    ComparisonOperator,
    EMAFilterValue,
    FilterType,
    FilterValue,
    IndicatorValue,
    MACD_FILTER_OPTION,
    MACD_LINES_BASELINE_LOCATION_OPTION,
    MACDFilterValue,
    MovingAveragePriceLocation,
    MovingAverageSpan,
    RSIFilterValue,
    SMAFilterValue,
    ValueFilterValue,
    VolumeFilterValue
} from './types';


export abstract class FilterPredicate {
    abstract filterType: FilterType;
    abstract filterValue: FilterValue;
    abstract process(indicatorValue: IndicatorValue): boolean;
    isMatch(filterType: FilterType): boolean {
        return filterType === this.filterType;
    }
}


export class ADXPredicate extends FilterPredicate {
    filterType: FilterType;
    filterValue: ADXFilterValue;

    constructor() {
        super();

        this.filterType = FilterType.ADX;
    }

    process(indicatorValue: IndicatorValue): boolean {
        const [lowerBound, upperBound] = this.filterValue.value;

        const adxValue = indicatorValue.adx;
        if (adxValue == null)
            return false;

        return (adxValue >= lowerBound) && (adxValue <= upperBound);
    }
}

const MA_SPAN_TO_EMA = {
    [MovingAverageSpan.MA9]: FilterType.EMA9,
    [MovingAverageSpan.MA21]: FilterType.EMA21,
    [MovingAverageSpan.MA50]: FilterType.EMA50,
    [MovingAverageSpan.MA100]: FilterType.EMA100,
    [MovingAverageSpan.MA200]: FilterType.EMA200,
}


export class EMAPredicate extends FilterPredicate {
    filterType: FilterType;
    filterValue: EMAFilterValue;

    private readonly movingAverageSpan: MovingAverageSpan;

    constructor(movingAverageSpan: MovingAverageSpan) {
        super();

        this.filterType = MA_SPAN_TO_EMA[movingAverageSpan];
        this.movingAverageSpan = movingAverageSpan;
    }

    process(indicatorValue: IndicatorValue): boolean {
        const emaValue = this.getEmaValue(indicatorValue);
        const currentPrice = indicatorValue.current_price;

        if (emaValue === null)
            return false;

        if (this.filterValue.value === MovingAveragePriceLocation.PRICE_ABOVE_AVERAGE)
            return currentPrice >= emaValue;

        if (this.filterValue.value === MovingAveragePriceLocation.PRICE_BELOW_AVERAGE)
            return currentPrice < emaValue;

        return false;
    }

    private getEmaValue(indicatorValue: IndicatorValue) {
        switch (this.movingAverageSpan) {
            case MovingAverageSpan.MA9:
                return indicatorValue.ema['9'];
            case MovingAverageSpan.MA21:
                return indicatorValue.ema['21'];
            case MovingAverageSpan.MA50:
                return indicatorValue.ema['50'];
            case MovingAverageSpan.MA100:
                return indicatorValue.ema['100'];
            case MovingAverageSpan.MA200:
                return indicatorValue.ema['200'];
        }
    }
}


export class MACDPredicate extends FilterPredicate {
    filterType: FilterType;
    filterValue: MACDFilterValue;

    constructor() {
        super();

        this.filterType = FilterType.MACD;
    }

    process(indicatorValue: IndicatorValue): boolean {
        const linePositioning = this.filterValue.value[0];

        const {macd} = indicatorValue;
        const macdValue = macd.macd;
        const signalValue = macd.signal;

        if (macdValue == null || signalValue == null)
            return false;

        let linePositionMatch;

        if (linePositioning === MACD_FILTER_OPTION.MACD_ABOVE_SIGNAL) {
            linePositionMatch = macdValue >= signalValue;
        } else {
            linePositionMatch = signalValue >= macdValue;
        }

        const baselinePosition = this.filterValue.value[1];

        let baseLinePositionMatch = true;
        if (baselinePosition === MACD_LINES_BASELINE_LOCATION_OPTION.ABOVE) {
            baseLinePositionMatch = macdValue > 0 && signalValue > 0;
        } else if (baselinePosition === MACD_LINES_BASELINE_LOCATION_OPTION.BELOW) {
            baseLinePositionMatch = macdValue < 0 && signalValue < 0;
        }

        return linePositionMatch && baseLinePositionMatch;
    }
}


export class RSIPredicate extends FilterPredicate {
    filterType: FilterType;
    filterValue: RSIFilterValue;

    constructor() {
        super();

        this.filterType = FilterType.RSI;
    }

    process(indicatorValue: IndicatorValue): boolean {
        const [lowerBound, upperBound] = this.filterValue.value;

        const rsiValue = indicatorValue.rsi;
        if (rsiValue == null)
            return false;

        return (rsiValue >= lowerBound) && (rsiValue <= upperBound);
    }
}


const MA_SPAN_TO_SMA = {
    [MovingAverageSpan.MA9]: FilterType.SMA9,
    [MovingAverageSpan.MA21]: FilterType.SMA21,
    [MovingAverageSpan.MA50]: FilterType.SMA50,
    [MovingAverageSpan.MA100]: FilterType.SMA100,
    [MovingAverageSpan.MA200]: FilterType.SMA200,
};

export class SMAPredicate extends FilterPredicate {
    filterType: FilterType;
    filterValue: SMAFilterValue;

    private readonly movingAverageSpan: MovingAverageSpan;

    constructor(movingAverageSpan: MovingAverageSpan) {
        super();

        this.filterType = MA_SPAN_TO_SMA[movingAverageSpan];
        this.movingAverageSpan = movingAverageSpan;
    }

    process(indicatorValue: IndicatorValue): boolean {
        const smaValue = this.getSmaValue(indicatorValue);
        const currentPrice = indicatorValue.current_price;

        if (smaValue === null)
            return false;

        if (this.filterValue.value === MovingAveragePriceLocation.PRICE_ABOVE_AVERAGE)
            return currentPrice >= smaValue;

        if (this.filterValue.value === MovingAveragePriceLocation.PRICE_BELOW_AVERAGE)
            return currentPrice < smaValue;

        return false;
    }

    private getSmaValue(indicatorValue: IndicatorValue) {
        switch (this.movingAverageSpan) {
            case MovingAverageSpan.MA9:
                return indicatorValue.sma['9'];
            case MovingAverageSpan.MA21:
                return indicatorValue.sma['21'];
            case MovingAverageSpan.MA50:
                return indicatorValue.sma['50'];
            case MovingAverageSpan.MA100:
                return indicatorValue.sma['100'];
            case MovingAverageSpan.MA200:
                return indicatorValue.sma['200'];
        }
    }
}


export class VolumePredicate extends FilterPredicate {
    filterType: FilterType;
    filterValue: VolumeFilterValue;

    constructor() {
        super();

        this.filterType = FilterType.VOLUME;
    }

    process(indicatorValue: IndicatorValue): boolean {
        const {operator, subject} = this.filterValue.value;

        if (operator === ComparisonOperator.GREATER_THAN && (indicatorValue.current_volume > subject))
            return true;

        return (operator === ComparisonOperator.LESS_THAN) && (indicatorValue.current_volume < subject);
    }
}


export class ValuePredicate extends FilterPredicate {
    filterType: FilterType;
    filterValue: ValueFilterValue;

    constructor() {
        super();

        this.filterType = FilterType.VALUE;
    }

    process(indicatorValue: IndicatorValue): boolean {
        const {operator, subject} = this.filterValue.value;

        const currentValue = indicatorValue.current_price * indicatorValue.current_volume;

        if (operator === ComparisonOperator.GREATER_THAN && (currentValue > subject))
            return true;

        return (operator === ComparisonOperator.LESS_THAN) && (currentValue < subject);
    }
}
