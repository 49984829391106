import React, {
    useContext,
    useEffect,
    useState
} from 'react';
import Select from 'antd/lib/select';
import InputNumber from 'antd/lib/input-number';

import FilterContainer from './FilterContainer';
import {FilterContext} from '../../pages/0/screener';
import {
    ComparisonOperator,
    ValueFilterValue,
} from './types';
import {useDebounce} from '../../hooks';

const {Option} = Select;


const DEFAULT_COMPARISON_OPERATOR = ComparisonOperator.GREATER_THAN;
const DEFAULT_SUBJECT_VALUE = 1000000;

function ValueFilter({type}) {
    const filterContext = useContext(FilterContext);

    const [comparisonOperator, setComparisonOperator] = useState(DEFAULT_COMPARISON_OPERATOR);
    const [subjectValue, setSubjectValue] = useState(DEFAULT_SUBJECT_VALUE);

    useEffect(() => {
        const filterValue: ValueFilterValue = {value: {operator: comparisonOperator, subject: subjectValue}};

        filterContext.onChange(type, filterValue);
    }, []);

    const debouncedSubjectValue = useDebounce(subjectValue, 250);

    useEffect(() => {
        const filterValue: ValueFilterValue = {value: {operator: comparisonOperator, subject: debouncedSubjectValue}};

        filterContext.onChange(type, filterValue);
    }, [debouncedSubjectValue]);

    function handleOperatorChange(operator) {
        const filterValue: ValueFilterValue = {value: {operator, subject: subjectValue}};

        filterContext.onChange(type, filterValue);
        setComparisonOperator(operator);
    }

    function handleSubjectChange(subject) {
        setSubjectValue(subject);
    }

    return (
        <FilterContainer title="Value" filter_type={type}>
            <div className="flex">
                <div className="w-1/3 mr-1">
                    <Select defaultValue={DEFAULT_COMPARISON_OPERATOR} onChange={handleOperatorChange}
                            className="w-full">
                        <Option value={ComparisonOperator.GREATER_THAN}>Above</Option>
                        <Option value={ComparisonOperator.LESS_THAN}>Below</Option>
                    </Select>
                </div>
                <div className="w-2/3">
                    <InputNumber defaultValue={DEFAULT_SUBJECT_VALUE}
                                 onChange={handleSubjectChange}
                                 className="w-full"
                                 step={DEFAULT_SUBJECT_VALUE}/>
                </div>
            </div>
        </FilterContainer>
    );
}

export default ValueFilter;
