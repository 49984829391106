import React, {
    useContext,
    useEffect
} from 'react';
import Slider from 'antd/lib/slider';

import FilterContainer from './FilterContainer';
import {FilterContext} from '../../pages/0/screener';
import {ADXFilterValue} from './types';


const DEFAULT_CUSTOM_RANGE: [number, number] = [31, 69];

function ADXFilter({ type }) {
    const filterContext = useContext(FilterContext);

    useEffect(() => {
        const value: ADXFilterValue = {value: DEFAULT_CUSTOM_RANGE};

        filterContext.onChange(type, value);
    }, []);

    function handleChange(value) {
        const filterValue: ADXFilterValue = {value};

        filterContext.onChange(type, filterValue);
    }

    function handleRangeChange(values) {
        handleChange(values);
    }

    return (
        <FilterContainer title="ADX (14, 14)" filter_type={type}>
            <div className="text-sm text-gray-500">Range</div>

            <Slider
                range={true}
                min={0}
                max={100}
                defaultValue={DEFAULT_CUSTOM_RANGE}
                onAfterChange={handleRangeChange}
            />
        </FilterContainer>
    );
}

export default ADXFilter;
