import React, {useContext, useEffect} from 'react';
import Select from 'antd/lib/select';

import FilterContainer from './FilterContainer';
import {FilterContext} from '../../pages/0/screener';
import {FILTER_TYPE} from '../../filter-types';
import {
    EMAFilterValue,
    MovingAveragePriceLocation
} from './types';

const {Option} = Select;

const EMA_TYPE_TITLE = {
    [FILTER_TYPE.EMA9]: 'EMA 9 Daily',
    [FILTER_TYPE.EMA21]: 'EMA 21 Daily',
    [FILTER_TYPE.EMA50]: 'EMA 50 Daily',
    [FILTER_TYPE.EMA100]: 'EMA 100 Daily',
    [FILTER_TYPE.EMA200]: 'EMA 200 Daily',
};

function EMAFilter({ type }) {
    const filterContext = useContext(FilterContext);

    useEffect(() => {
        const filterValue: EMAFilterValue = { value: MovingAveragePriceLocation.PRICE_ABOVE_AVERAGE };

        filterContext.onChange(type, filterValue);
    }, []);

    function handleChange(value) {
        const filterValue: EMAFilterValue = { value };

        filterContext.onChange(type, filterValue);
    }

    return (
        <FilterContainer title={EMA_TYPE_TITLE[type]} filter_type={type}>
            <Select defaultValue={MovingAveragePriceLocation.PRICE_ABOVE_AVERAGE}
                    onChange={handleChange}
                    className="w-full">
                <Option value={MovingAveragePriceLocation.PRICE_ABOVE_AVERAGE}>Price above average</Option>
                <Option value={MovingAveragePriceLocation.PRICE_BELOW_AVERAGE}>Price below average</Option>
            </Select>
        </FilterContainer>
    );
}

export default EMAFilter;
