import React, {
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import {Link} from 'gatsby';
import Menu from 'antd/lib/menu';
import Dropdown from 'antd/lib/dropdown';
import Table from 'antd/lib/table';
import Popconfirm from 'antd/lib/popconfirm';
import numeral from 'numeral';
import {CSSTransitionGroup} from 'react-transition-group';
import PropTypes from 'prop-types';
import List from 'antd/lib/list';
import message from 'antd/lib/message';
import styled from 'styled-components';

import Layout from '../../components/layout';
import {
    getIndicatorValues,
    getStocks
} from '../../api';
import {
    generateRoute,
    getSorter,
    getStringSorter,
    ROUTES,
    LOADING_STATES
} from '../../common';
import {useWindowSize} from '../../hooks';
import FilterRenderer from '../../components/screener/FilterRenderer';
import '../../styles/screener.css';
import SEO from '../../components/SEO';
import {
    FilterType,
    FilterValue,
    IndicatorValue,
    MovingAverageSpan
} from '../../components/screener/types';
import {
    ADXPredicate,
    EMAPredicate,
    FilterPredicate,
    MACDPredicate,
    RSIPredicate,
    SMAPredicate,
    ValuePredicate,
    VolumePredicate
} from '../../components/screener/FilterPredicates';

const {Column} = Table;
const {SubMenu} = Menu;
const OFFSET_HEIGHT = 190;

const MobileResultListContainer = styled.div`

`;

const sortByStockCode = getStringSorter('stock_code');

interface FilterValueChange {
    (filterType: FilterType, value: FilterValue): void
}

interface RemoveFilter {
    (filterType: FilterType): void
}

interface FilterContextInterface {
    onChange?: FilterValueChange,
    onRemove?: RemoveFilter
}

export const FilterContext = React.createContext<FilterContextInterface>({});

interface ScreenerContextInterface {
    stocks?: object,
    loadingStatus?: boolean,
    filterList?: FilterPredicate[],
    filteredIndicatorValues?: Array<any>,
    onConfirmClearFilters?: () => void,
    onAddFilter?: (filterType: FilterType) => void,
    onApplyFilters?: () => void,
    onRemoveFilter?: (type: FilterType) => void,
    onFilterValueChange?: (filterType: FilterType, value: any) => void,
}

const ScreenerContext = React.createContext<ScreenerContextInterface>({});

function FilterDropdown({children}) {
    const screenerContext = useContext(ScreenerContext);
    const {onAddFilter, filterList} = screenerContext;

    const filterListMap = useMemo(() => {
        const map = {};
        for (const fl of filterList) {
            map[fl.filterType] = 1;
        }

        return map;
    }, [filterList]);

    function handleOnClick({key}) {
        onAddFilter(key as FilterType);
    }

    const menu = (
        <Menu onClick={handleOnClick}>
            <Menu.Item key={FilterType.ADX} disabled={filterListMap[FilterType.ADX]}>Average Directional
                Index</Menu.Item>
            <SubMenu title="Exponential Moving Average">
                <Menu.Item key={FilterType.EMA9} disabled={filterListMap[FilterType.EMA9]}>EMA 9</Menu.Item>
                <Menu.Item key={FilterType.EMA21} disabled={filterListMap[FilterType.EMA21]}>EMA 21</Menu.Item>
                <Menu.Item key={FilterType.EMA50} disabled={filterListMap[FilterType.EMA50]}>EMA 50</Menu.Item>
                <Menu.Item key={FilterType.EMA100} disabled={filterListMap[FilterType.EMA100]}>EMA 100</Menu.Item>
                <Menu.Item key={FilterType.EMA200} disabled={filterListMap[FilterType.EMA200]}>EMA 200</Menu.Item>
            </SubMenu>
            <Menu.Item key={FilterType.MACD} disabled={filterListMap[FilterType.MACD]}>Moving Average Convergence
                Divergence</Menu.Item>
            <Menu.Item key={FilterType.RSI} disabled={filterListMap[FilterType.RSI]}>Relative Strength Index</Menu.Item>
            <SubMenu title="Simple Moving Average">
                <Menu.Item key={FilterType.SMA9} disabled={filterListMap[FilterType.SMA9]}>SMA 9</Menu.Item>
                <Menu.Item key={FilterType.SMA21} disabled={filterListMap[FilterType.SMA21]}>SMA 21</Menu.Item>
                <Menu.Item key={FilterType.SMA50} disabled={filterListMap[FilterType.SMA50]}>SMA 50</Menu.Item>
                <Menu.Item key={FilterType.SMA100} disabled={filterListMap[FilterType.SMA100]}>SMA 100</Menu.Item>
                <Menu.Item key={FilterType.SMA200} disabled={filterListMap[FilterType.SMA200]}>SMA 200</Menu.Item>
            </SubMenu>
            <Menu.Item key={FilterType.VALUE} disabled={filterListMap[FilterType.VALUE]}>Value</Menu.Item>
            <Menu.Item key={FilterType.VOLUME} disabled={filterListMap[FilterType.VOLUME]}>Volume</Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            {children}
        </Dropdown>
    );
}

FilterDropdown.propTypes = {
    children: PropTypes.node
}

function PlaceholderAddFilter() {
    const {loadingStatus} = useContext(ScreenerContext);

    const main = (
        <>
            <svg className="mx-auto h-8 w-8 text-gray-300" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd"
                      d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                      clipRule="evenodd"/>
            </svg>
            <p className="mt-1 text-sm text-gray-600">
                Add a filter to start screening
            </p>
        </>
    );

    const loading = (
        <>
            <svg className="mx-auto h-8 w-8 text-gray-300" fill="currentColor" viewBox="0 0 20 20">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"/>
            </svg>
            <p className="mt-1 text-sm text-gray-600">
                Fetching indicator data... Please wait for a while.
            </p>
        </>
    );

    const isLoading = loadingStatus === LOADING_STATES.LOADING;

    return (
        <FilterDropdown>
            <div
                className="flex justify-center p-4 border-2 border-gray-300 border-dashed rounded-md hover:bg-gray-100 cursor-pointer">
                <div className="text-center">
                    {isLoading ? loading : main}
                </div>
            </div>
        </FilterDropdown>
    );
}

PlaceholderAddFilter.propTypes = {
    onAddFilter: PropTypes.func,
    filterListMap: PropTypes.object
};

function approximate(num) {
    return numeral(num).format('0.0a');
}

function toPercent(num) {
    return numeral(num).format('0.00') + ' %';
}

function calculatePercentChange(record) {
    const priceDelta = record.current_price - record.previous_price;
    const percentChange = 100 * (priceDelta / record.previous_price);

    return percentChange;
}

function getChangeColorClassName(percentChange) {
    if (percentChange < 0) {
        return 'text-red-600';
    }
    if (percentChange > 0) {
        return 'text-green-600';
    }

    return 'text-gray-500';
}

function calculateValue(record) {
    return record.current_price * record.current_volume;
}

function DesktopView() {
    const {
        stocks,
        filterList,
        filteredIndicatorValues,
        onConfirmClearFilters,
        onApplyFilters,
        onFilterValueChange,
        onRemoveFilter
    } = useContext(ScreenerContext);

    // eslint-disable-next-line no-unused-vars
    const [width, height] = useWindowSize();


    const filtersDisplay = (
        <>
            <div className="flex items-center mx-4 my-1">
                <div className="flex-1">
                    <h3 className="inline-flex items-center mb-0 text-sm leading-6 font-normal text-gray-500">
                        <svg className="mr-1 h-4 w-4 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                            <path fillRule="evenodd"
                                  d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                                  clipRule="evenodd"/>
                        </svg>
                        Filters
                    </h3>
                </div>
                <div>
                    <Popconfirm
                        title="Are you sure you want to clear all filters?"
                        onConfirm={onConfirmClearFilters}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a href="#"
                           className="inline-flex items-center mr-4 text-gray-600 focus:outline-none focus:underline transition duration-150 ease-in-out">
                            <svg className="-ml-0.5 mr-1 h-4 w-4" fill="currentColor"
                                 viewBox="0 0 20 20">
                                <path fillRule="evenodd"
                                      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                      clipRule="evenodd"></path>
                            </svg>
                            Clear All
                        </a>
                    </Popconfirm>
                    <FilterDropdown>
                        <button
                            className="inline-flex items-center font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition duration-150 ease-in-out">
                            <svg className="-ml-0.5 mr-1 h-4 w-4" fill="currentColor"
                                 viewBox="0 0 20 20">
                                <path fillRule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                                      clipRule="evenodd"></path>
                            </svg>
                            Add
                        </button>
                    </FilterDropdown>
                </div>
            </div>

            <FilterContext.Provider
                value={{onChange: onFilterValueChange, onRemove: onRemoveFilter}}>
                <CSSTransitionGroup
                    transitionName="filter"
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={300}>
                    {filterList.map((filter) => {
                        return <FilterRenderer type={filter.filterType} key={filter.filterType}/>;
                    })}
                </CSSTransitionGroup>
            </FilterContext.Provider>

            <div className="flex self-end mx-4 my-2">
                <button onClick={onApplyFilters}
                        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:ring active:bg-blue-700 transition ease-in-out duration-150">
                    Apply
                </button>
            </div>
        </>
    );

    function sortByCompanyName(a, b) {
        const stockA = stocks[a.stock_code];
        const stockB = stocks[b.stock_code];

        const nameA = stockA.company_name.toUpperCase();
        const nameB = stockB.company_name.toUpperCase();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;

        return 0;
    }

    function sortByPercentChange(a, b) {
        const aPercentChange = calculatePercentChange(a);
        const bPercentChange = calculatePercentChange(b);

        if (aPercentChange < bPercentChange) return -1;
        if (aPercentChange > bPercentChange) return 1;

        return 0;
    }

    function sortByValue(a, b) {
        const aValue = calculateValue(a);
        const bValue = calculateValue(b);

        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;

        return 0;
    }

    return (
        <div className="hidden lg:flex h-full overflow-y-auto bg-gray-100">
            <div className="flex flex-col w-3/12 border-r border-gray-200 bg-white">
                <div className="h-0 flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                    {filterList.length ? filtersDisplay :
                        <div className="p-4"><PlaceholderAddFilter/></div>}
                </div>
            </div>
            <div className="mt-1.5 p-5 flex-1">
                <h3 className="mx-1 mb-3 text-sm leading-5 font-medium text-gray-500 truncate">Results
                    ({filteredIndicatorValues.length})</h3>

                <div className="bg-white shadow rounded">
                    <Table dataSource={filteredIndicatorValues}
                           rowKey="stock_code"
                           pagination={false}
                           scroll={{y: height - OFFSET_HEIGHT}}>
                        <Column title="Ticker"
                                width={90}
                                sorter={sortByStockCode}
                                render={(text, record) => {
                                    const _record: IndicatorValue = record as IndicatorValue;

                                    return (
                                        <Link
                                            to={generateRoute(ROUTES.STOCKS, {stock_code: _record.stock_code})}
                                            className="text-blue-500 hover:text-blue-600 hover:underline">
                                            {_record.stock_code}
                                        </Link>
                                    );
                                }}/>
                        <Column title="Company"
                                width={400}
                                sorter={sortByCompanyName}
                                render={(text, record) => {
                                    const stock = stocks[record.stock_code];

                                    return stock.company_name;
                                }}/>
                        <Column title="% Change"
                                sorter={sortByPercentChange}
                                render={(text, record) => {
                                    const percentChange = calculatePercentChange(record);

                                    return <span
                                        className={getChangeColorClassName(percentChange)}>{toPercent(percentChange)}</span>;
                                }}/>
                        <Column title="Close" dataIndex="current_price" sorter={getSorter('current_price')}/>
                        <Column title="Value" sorter={sortByValue} render={(text, record) => {
                            const value = calculateValue(record);

                            return approximate(value);
                        }}/>
                    </Table>
                </div>
            </div>
        </div>
    );
}

function MobileView() {
    const {
        stocks,
        filteredIndicatorValues,
        filterList,
        onFilterValueChange,
        onRemoveFilter,
        onApplyFilters,
        onConfirmClearFilters
    } = useContext(ScreenerContext);

    const [showFilters, setShowFilters] = useState(true);

    function handleToggleShowFilters() {
        setShowFilters((prev) => !prev);
    }

    function handleApplyFilters(_e) {
        onApplyFilters();
    }

    const filtersDisplay = (
        <div className="border-b p-4">
            <div className="space-x-2 flex">
                <div className="flex-1">
                    <button type="button"
                            onClick={handleToggleShowFilters}
                            className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                        <svg className="mr-1 h-4 w-4 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                            <path fillRule="evenodd"
                                  d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                                  clipRule="evenodd"/>
                        </svg>
                        {showFilters ? 'Hide Filters' : 'Show Filters'}
                    </button>
                </div>
                <div className={`${showFilters ? 'block' : 'hidden'} space-x-2`}>
                    <Popconfirm
                        title="Are you sure you want to clear all filters?"
                        onConfirm={onConfirmClearFilters}
                        okText="Yes"
                        cancelText="No"
                    >
                        <button type="button"
                                className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                            <svg className="-ml-0.5 mr-1 h-4 w-4 text-gray-400" fill="currentColor"
                                 viewBox="0 0 20 20">
                                <path fillRule="evenodd"
                                      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                      clipRule="evenodd"/>
                            </svg>
                            Clear All
                        </button>
                    </Popconfirm>
                    <FilterDropdown>
                        <button type="button"
                                className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                            <svg className="-ml-0.5 mr-1 h-4 w-4 text-gray-400" fill="currentColor"
                                 viewBox="0 0 20 20">
                                <path fillRule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                                      clipRule="evenodd"/>
                            </svg>
                            Add Filter
                        </button>
                    </FilterDropdown>
                </div>
            </div>
            <div className={`${showFilters ? 'block' : 'hidden'} mt-4`}>
                <FilterContext.Provider
                    value={{onChange: onFilterValueChange, onRemove: onRemoveFilter}}>
                    <CSSTransitionGroup
                        transitionName="filter"
                        transitionEnterTimeout={500}
                        transitionLeaveTimeout={300}>
                        {filterList.map((filter) => {
                            return <FilterRenderer type={filter.filterType} key={filter.filterType}/>;
                        })}
                    </CSSTransitionGroup>
                </FilterContext.Provider>
                <div className="m-4">
                    <button onClick={handleApplyFilters}
                            className="px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:ring active:bg-blue-700 transition ease-in-out duration-150 w-full text-center">
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );

    const hasFilters = filterList.length;

    return (
        <div className="flex flex-col lg:hidden h-full overflow-hidden">
            <div>
                {hasFilters ? filtersDisplay :
                    <div className="p-4"><PlaceholderAddFilter/></div>}
            </div>
            <div className={`pt-5 h-full overflow-y-auto ${hasFilters ? '' : 'hidden'}`}>
                <List header={<h3 className="mx-5 text-sm leading-5 font-medium text-gray-500 truncate">Results
                    ({filteredIndicatorValues.length})</h3>}
                      dataSource={filteredIndicatorValues}
                      renderItem={(item) => {
                          const stock = stocks[item.stock_code];

                          const percentChange = calculatePercentChange(item);
                          const priceColorClass = getChangeColorClassName(percentChange);

                          return (
                              <div
                                  className="flex items-center border-t border-gray-200 px-4 py-4 sm:px-6 bg-white">
                                  <div className="min-w-0 flex-1 flex items-center">
                                      <div className="min-w-0 flex-1 px-1.5 md:grid md:grid-cols-2 md:gap-4">
                                          <div>
                                              <div
                                                  className="text-sm leading-5 font-medium truncate">
                                                  <Link
                                                      to={generateRoute(ROUTES.STOCKS, {stock_code: item.stock_code})}
                                                      className="text-blue-500 hover:text-blue-600 hover:underline text-base">
                                                      {item.stock_code}
                                                  </Link>
                                              </div>
                                              <div
                                                  className="mt-2 flex items-center text-sm leading-5 text-gray-500">
                                                  <span className="truncate">
                                                      {stock.company_name}
                                                  </span>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className={`flex flex-col items-end px-1.5 ${priceColorClass}`}>
                                      <div className="font-bold">{item.current_price}</div>
                                      <div className="text-xs">{toPercent(percentChange)}</div>
                                  </div>
                              </div>
                          )
                      }}/>
            </div>
        </div>
    );
}

const EMA_TYPES = {
    [FilterType.EMA9]: 1,
    [FilterType.EMA21]: 1,
    [FilterType.EMA50]: 1,
    [FilterType.EMA100]: 1,
    [FilterType.EMA200]: 1,
};

const SMA_TYPES = {
    [FilterType.SMA9]: 1,
    [FilterType.SMA21]: 1,
    [FilterType.SMA50]: 1,
    [FilterType.SMA100]: 1,
    [FilterType.SMA200]: 1,
};

const MA_SPAN_MAP = {
    [FilterType.EMA9]: MovingAverageSpan.MA9,
    [FilterType.EMA21]: MovingAverageSpan.MA21,
    [FilterType.EMA50]: MovingAverageSpan.MA50,
    [FilterType.EMA100]: MovingAverageSpan.MA100,
    [FilterType.EMA200]: MovingAverageSpan.MA200,
    [FilterType.SMA9]: MovingAverageSpan.MA9,
    [FilterType.SMA21]: MovingAverageSpan.MA21,
    [FilterType.SMA50]: MovingAverageSpan.MA50,
    [FilterType.SMA100]: MovingAverageSpan.MA100,
    [FilterType.SMA200]: MovingAverageSpan.MA200,
};

function ScreenerPage() {
    const [stocks, setStocks] = useState({});
    const [indicatorValues, setIndicatorValues] = useState<IndicatorValue[]>([]);
    const [loadingStatus, setLoadingStatus] = useState(LOADING_STATES.IDLE);

    const [filterList, setFilterList] = useState<FilterPredicate[]>([]);
    const [filteredIndicatorValues, setFilteredIndicatorValues] = useState([]);

    useEffect(function () {
        function fetchStocks() {
            return new Promise((resolve) => {
                getStocks()
                    .then(({data}) => {
                        const map = {};
                        for (const stock of data.stocks) {
                            map[stock.ticker_symbol] = stock;
                        }

                        resolve(map);
                    });
            });
        }

        function fetchIndicatorValues(): Promise<Array<IndicatorValue>> {
            return new Promise<Array<IndicatorValue>>((resolve) => {
                getIndicatorValues()
                    .then(({data}) => {
                        resolve(data.indicator_values);
                    });
            });
        }

        setLoadingStatus(LOADING_STATES.LOADING);

        Promise.all<object, Array<IndicatorValue>>([fetchStocks(), fetchIndicatorValues()])
            .then(([stocks, indicatorValues]) => {
                setStocks(stocks);
                setIndicatorValues(indicatorValues);

                setLoadingStatus(LOADING_STATES.IDLE);
            })
            .catch(() => {
                setLoadingStatus(LOADING_STATES.IDLE);

                message.error('Could not retrieve indicator values at this time. Please try again later');
            });
    }, []);

    function handleApplyFilters() {
        const filteredIndicatorValues = indicatorValues.filter((indicatorValue) => {
            return filterList.every((filter) => filter.process(indicatorValue));
        });

        // @ts-ignore
        plausible('ScreenerApplyFilters');

        setFilteredIndicatorValues(filteredIndicatorValues.sort(sortByStockCode));

        if (!filteredIndicatorValues.length) {
            message.info('Zero matched. Try to loosen your filters.');
            return;
        }

        const messageText = filteredIndicatorValues.length > 1 ? `${filteredIndicatorValues.length} results` : '1 result';
        message.info(messageText);
    }

    function handleAddFilter(filterType: FilterType) {
        let predicate;

        if (filterType === FilterType.ADX) {
            predicate = new ADXPredicate();
        } else if (EMA_TYPES[filterType]) {
            predicate = new EMAPredicate(MA_SPAN_MAP[filterType]);
        } else if (filterType === FilterType.MACD) {
            predicate = new MACDPredicate();
        } else if (filterType === FilterType.RSI) {
            predicate = new RSIPredicate();
        } else if (SMA_TYPES[filterType]) {
            predicate = new SMAPredicate(MA_SPAN_MAP[filterType]);
        } else if (filterType === FilterType.VOLUME) {
            predicate = new VolumePredicate();
        } else if (filterType === FilterType.VALUE) {
            predicate = new ValuePredicate();
        } else {
            return;
        }

        setFilterList([...filterList, predicate]);
    }

    function handleFilterValueChange(filterType: FilterType, value: FilterValue) {
        const _filterList = filterList.map((fl) => {
            if (fl.isMatch(filterType))
                fl.filterValue = value;

            return fl;
        });

        setFilterList(_filterList);
    }

    function handleRemoveFilter(type: FilterType) {
        setFilterList(filterList.filter((fl) => !fl.isMatch(type)));
    }

    function handleConfirmClearFilters() {
        setFilterList([]);
        setFilteredIndicatorValues([]);
    }

    const contextValue = {
        stocks,
        filterList,
        filteredIndicatorValues,
        loadingStatus,
        onConfirmClearFilters: handleConfirmClearFilters,
        onAddFilter: handleAddFilter,
        onApplyFilters: handleApplyFilters,
        onRemoveFilter: handleRemoveFilter,
        onFilterValueChange: handleFilterValueChange
    };

    return (
        <Layout>
            <SEO title="Screener" description="Screen stocks with technical indicators."/>
            <ScreenerContext.Provider value={contextValue}>
                <DesktopView/>
                <MobileView/>
            </ScreenerContext.Provider>
        </Layout>
    )
}


export default ScreenerPage
