export const FILTER_TYPE = {
    RSI: 'RSI',
    MACD: 'MACD',
    SMA9: 'SMA9',
    SMA21: 'SMA21',
    SMA50: 'SMA50',
    SMA100: 'SMA100',
    SMA200: 'SMA200',
    EMA9: 'EMA9',
    EMA21: 'EMA21',
    EMA50: 'EMA50',
    EMA100: 'EMA100',
    EMA200: 'EMA200',
    ADX: 'ADX'
};
