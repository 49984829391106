import React, {useContext, useEffect} from 'react';
import Select from 'antd/lib/select';

import FilterContainer from './FilterContainer';
import {FilterContext} from '../../pages/0/screener';
import {FILTER_TYPE} from '../../filter-types';
import {
    MovingAveragePriceLocation,
    SMAFilterValue
} from './types';

const {Option} = Select;

const SMA_TYPE_TITLE = {
    [FILTER_TYPE.SMA9]: 'SMA 9 Daily',
    [FILTER_TYPE.SMA21]: 'SMA 21 Daily',
    [FILTER_TYPE.SMA50]: 'SMA 50 Daily',
    [FILTER_TYPE.SMA100]: 'SMA 100 Daily',
    [FILTER_TYPE.SMA200]: 'SMA 200 Daily',
};

function SMAFilter({ type }) {
    const filterContext = useContext(FilterContext);

    useEffect(() => {
        const filterValue: SMAFilterValue = {value: MovingAveragePriceLocation.PRICE_ABOVE_AVERAGE};

        filterContext.onChange(type, filterValue);
    }, []);

    function handleChange(value) {
        const filterValue: SMAFilterValue = {value};

        filterContext.onChange(type, filterValue);
    }

    return (
        <FilterContainer title={SMA_TYPE_TITLE[type]} filter_type={type}>
            <Select defaultValue={MovingAveragePriceLocation.PRICE_ABOVE_AVERAGE}
                    onChange={handleChange}
                    className="w-full">
                <Option value={MovingAveragePriceLocation.PRICE_ABOVE_AVERAGE}>Price above average</Option>
                <Option value={MovingAveragePriceLocation.PRICE_BELOW_AVERAGE}>Price below average</Option>
            </Select>
        </FilterContainer>
    );
}

export default SMAFilter;
