import React, {useContext, useEffect, useState} from 'react';
import Select from 'antd/lib/select';
import Divider from 'antd/lib/divider';
import Slider from 'antd/lib/slider';

const {Option} = Select;

import FilterContainer from './FilterContainer';
import {FilterContext} from '../../pages/0/screener';
import {RSIFilterValue} from './types';


const RSI_OVERBOUGHT_VALUE: [number, number] = [70, 100];
const RSI_OVERSOLD_VALUE: [number, number] = [0, 30];
const RSI_DEFAULT_VALUE: [number, number] = RSI_OVERBOUGHT_VALUE;
const RSI_DEFAULT_CUSTOM_RANGE: [number, number] = [31, 69];
const RSI_FILTER_OPTION = {
    OVERBOUGHT: 'overbought',
    OVERSOLD: 'oversold',
    CUSTOM: 'custom'
};

function RSIFilter({ type }) {
    const filterContext = useContext(FilterContext);

    const defaultValue = RSI_FILTER_OPTION.OVERBOUGHT;
    const [optionSelected, setOptionSelected] = useState(defaultValue);

    useEffect(() => {
        const filterValue: RSIFilterValue = { value: RSI_DEFAULT_VALUE };

        filterContext.onChange(type, filterValue);
    }, []);

    function handleChange(value) {
        const filterValue: RSIFilterValue = { value };

        filterContext.onChange(type, filterValue);
    }

    function handleOptionChange(option) {
        setOptionSelected(option);

        if (option === RSI_FILTER_OPTION.OVERBOUGHT)
            handleChange(RSI_OVERBOUGHT_VALUE);
        else if (option === RSI_FILTER_OPTION.OVERSOLD)
            handleChange(RSI_OVERSOLD_VALUE);
        else if (option === RSI_FILTER_OPTION.CUSTOM)
            handleChange(RSI_DEFAULT_CUSTOM_RANGE);
    }

    function handleCustomRangeChange(values) {
        handleChange(values);
    }

    return (
        <FilterContainer title="RSI (14)" filter_type={type}>
            <Select defaultValue={defaultValue} onChange={handleOptionChange} className="w-full">
                <Option value={RSI_FILTER_OPTION.OVERBOUGHT}>Overbought</Option>
                <Option value={RSI_FILTER_OPTION.OVERSOLD}>Oversold</Option>
                <Option value={RSI_FILTER_OPTION.CUSTOM}>Custom</Option>
            </Select>

            {optionSelected === RSI_FILTER_OPTION.CUSTOM && (
                <>
                    <Divider/>
                    <div className="text-sm text-gray-500">Range</div>

                    <Slider
                        range={true}
                        min={0}
                        max={100}
                        defaultValue={RSI_DEFAULT_CUSTOM_RANGE}
                        onAfterChange={handleCustomRangeChange}
                    />
                </>
            )}
        </FilterContainer>
    );
}

export default RSIFilter;
