import React, {
    useContext,
    useEffect,
    useState
} from 'react';
import Select from 'antd/lib/select';

import FilterContainer from './FilterContainer';
import {FilterContext} from '../../pages/0/screener';
import {
    MACD_FILTER_OPTION,
    MACD_LINES_BASELINE_LOCATION_OPTION,
    MACDFilterValue
} from './types';

const {Option} = Select;

function MACDFilter({type}) {

    const filterContext = useContext(FilterContext);

    useEffect(() => {
        const filterValue: MACDFilterValue = {
            value: [
                MACD_FILTER_OPTION.MACD_ABOVE_SIGNAL,
                MACD_LINES_BASELINE_LOCATION_OPTION.NEUTRAL
            ]
        };

        filterContext.onChange(type, filterValue);
    }, []);

    const [optionSelected, setOptionSelected] = useState(MACD_FILTER_OPTION.MACD_ABOVE_SIGNAL);
    const [linesLocationOptionSelected, setLinesLocationOptionSelected] = useState(MACD_LINES_BASELINE_LOCATION_OPTION.NEUTRAL);

    function handleChange(filterValue: MACDFilterValue) {
        filterContext.onChange(type, filterValue);
    }

    function handleOptionChange(option) {
        setOptionSelected(option);

        const filterValue: MACDFilterValue = { value: [option, linesLocationOptionSelected] };

        handleChange(filterValue);
    }

    function handleLinesBaselineLocationOptionChange(option) {
        setLinesLocationOptionSelected(option);

        const filterValue: MACDFilterValue = { value: [optionSelected, option] };

        handleChange(filterValue);
    }

    return (
        <FilterContainer title="MACD (12, 26, close, 9)" filter_type={type}>
            <Select defaultValue={optionSelected}
                    onChange={handleOptionChange}
                    className="w-full">
                <Option value={MACD_FILTER_OPTION.MACD_ABOVE_SIGNAL}>MACD line above Signal line</Option>
                <Option value={MACD_FILTER_OPTION.SIGNAL_ABOVE_MACD}>Signal line above MACD line</Option>
            </Select>

            <div className="mt-3">
                <label className="block mb-1 mt-2 text-sm leading-5 text-gray-500">
                    Baseline
                </label>
                <Select defaultValue={linesLocationOptionSelected}
                        onChange={handleLinesBaselineLocationOptionChange}
                        className="w-full">
                    <Option value={MACD_LINES_BASELINE_LOCATION_OPTION.NEUTRAL}>Neutral</Option>
                    <Option value={MACD_LINES_BASELINE_LOCATION_OPTION.ABOVE}>MACD & Signal lines above baseline</Option>
                    <Option value={MACD_LINES_BASELINE_LOCATION_OPTION.BELOW}>MACD & Signal lines below baseline</Option>
                </Select>
            </div>
        </FilterContainer>
    );
}

export default MACDFilter;
