import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {FilterContext} from '../../pages/0/screener';


function FilterContainer({title, children, filter_type}) {
    const filterContext = useContext(FilterContext);

    return (
        <div className="m-4 mt-1">
            <div className="border border-gray-100 p-4 rounded shadow bg-white">
                <header className="flex items-center">
                    <h4 className="text-sm leading-5 font-medium text-gray-500 truncate flex-1 mb-0">{title}</h4>
                    <button onClick={() => filterContext.onRemove(filter_type)} className="text-gray-400 hover:text-gray-500 transition duration-150 ease-in-out">
                        <svg fill="currentColor" viewBox="0 0 20 20" className="h-4 w-4">
                            <path fillRule="evenodd"
                                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                  clipRule="evenodd"></path>
                        </svg>
                    </button>
                </header>

                <div className="mt-3">
                    {children}
                </div>
            </div>
        </div>
    )
}
FilterContainer.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    filter_type: PropTypes.string
}

export default FilterContainer;
