import React from 'react';
import SMAFilter from './SMAFilter';
import ADXFilter from './ADXFilter';
import EMAFilter from './EMAFilter';
import MACDFilter from './MACDFilter';
import RSIFilter from './RSIFilter';
import {FilterType} from './types';
import ValueFilter from './ValueFilter';
import VolumeFilter from './VolumeFilter';

const FILTER_COMPONENT = {
    [FilterType.ADX]: ADXFilter,
    [FilterType.EMA9]: EMAFilter,
    [FilterType.EMA21]: EMAFilter,
    [FilterType.EMA50]: EMAFilter,
    [FilterType.EMA100]: EMAFilter,
    [FilterType.EMA200]: EMAFilter,
    [FilterType.MACD]: MACDFilter,
    [FilterType.RSI]: RSIFilter,
    [FilterType.SMA9]: SMAFilter,
    [FilterType.SMA21]: SMAFilter,
    [FilterType.SMA50]: SMAFilter,
    [FilterType.SMA100]: SMAFilter,
    [FilterType.SMA200]: SMAFilter,
    [FilterType.VALUE]: ValueFilter,
    [FilterType.VOLUME]: VolumeFilter,
};

function FilterRenderer({type}) {
    const FilterType = FILTER_COMPONENT[type];

    return (
        <FilterType type={type} />
    );
}

export default FilterRenderer;
