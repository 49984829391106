export interface IndicatorValue {
    stock_code: string,
    previous_price: number,
    current_price: number,
    current_volume: number,
    updated_on: string,
    adx: number,
    ema: {
        '9': number,
        '21': number,
        '50': number,
        '100': number,
        '200': number
    },
    macd: {
        histogram: number,
        macd: number,
        signal: number
    },
    rsi: number,
    sma: {
        '9': number,
        '21': number,
        '50': number,
        '100': number,
        '200': number
    },
}

export enum MovingAverageSpan {
    MA9 = '9',
    MA21 = '21',
    MA50 = '50',
    MA100 = '100',
    MA200 = '200',
}

export enum FilterType {
    ADX = 'ADX',
    EMA9 = 'EMA9',
    EMA21 = 'EMA21',
    EMA50 = 'EMA50',
    EMA100 = 'EMA100',
    EMA200 = 'EMA200',
    MACD = 'MACD',
    RSI = 'RSI',
    SMA9 = 'SMA9',
    SMA21 = 'SMA21',
    SMA50 = 'SMA50',
    SMA100 = 'SMA100',
    SMA200 = 'SMA200',
    VOLUME = 'VOLUME',
    VALUE = 'VALUE',
}

export interface FilterValue {
    value: any;
}

export interface ADXFilterValue extends FilterValue {
    value: [number, number];
}

export enum MovingAveragePriceLocation {
    PRICE_ABOVE_AVERAGE,
    PRICE_BELOW_AVERAGE
}

export interface EMAFilterValue extends FilterValue {
    value: MovingAveragePriceLocation
}

export enum MACD_FILTER_OPTION {
    MACD_ABOVE_SIGNAL = 'MACD_ABOVE_SIGNAL',
    SIGNAL_ABOVE_MACD = 'SIGNAL_ABOVE_MACD'
}

export enum MACD_LINES_BASELINE_LOCATION_OPTION {
    NEUTRAL = 'neutral',
    ABOVE ='above',
    BELOW = 'below'
}

export interface MACDFilterValue extends FilterValue {
    value: [MACD_FILTER_OPTION, MACD_LINES_BASELINE_LOCATION_OPTION]
}

export interface RSIFilterValue extends FilterValue {
    value: [number, number]
}

export interface SMAFilterValue extends FilterValue {
    value: MovingAveragePriceLocation
}

export enum ComparisonOperator {
    GREATER_THAN = 'GREATER_THAN',
    LESS_THAN = 'LESS_THAN',
}

export interface ComparisonOperatorValue {
    operator: ComparisonOperator,
    subject: number
}

export interface VolumeFilterValue extends FilterValue {
    value: ComparisonOperatorValue
}

export interface ValueFilterValue extends FilterValue {
    value: ComparisonOperatorValue
}
